.filter-paused {
  color: rgba(40, 40, 40, 0.2)!important;
  pointer-events: none;
}

.filter-paused h2 {
  color: rgba(40, 40, 40, 0.2)!important;
}

.filter-paused .filter-left {
  color: rgba(40, 40, 40, 0.2)!important;
}

.back-to-filter {
  display: block;
  background-image: url('../../../img/Feld_Zurueck-zum-Filter.png'), url('../../../img/Icon_Filter.svg');
  background-repeat: no-repeat;
  background-size: contain, 25%;
  background-position: center, center 25px;
  width: 245px;
  height: 150px;
  color: $theme-primary;
  text-align: center;
  font-weight: bold;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

.back-to-filter > span {
  position: absolute;
  bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  font-size: 20px;
}

.back-to-filter:hover {
  color: $theme-primary;
}

.eventdetail__infoline > span {
  font-size: 20px;
  font-weight: bold;
  color: $theme-primary;
}

.workshoplist__item {
  text-align: left;
  font-weight: bold;
  background-color: $theme-primary;
  color: #fff;
  display: block;
  width: 100%;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 0 0 15px;
  margin-bottom: 15px;
  font-size: 12px;
  height: 60px;
  line-height: 60px;
  overflow: hidden;
}

a.workshoplist__item {
  &:hover, &:focus, &:active {
    color: #fff;
  }
}

.workshoplist__item-status {
  top: -2px;
  position: relative;
  width: 24px;
}

.workshoplist__item-btn {
  border-left: 1px solid #fff;
  padding-left: 15px;
  padding-right: 50px;
  background-image: url('../../img/auswaehlen.svg');
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 28px;
}

.workshoplist__item-btn--selected {
  background-image: url('../../img/ausgewaehlt.svg');
}

.workshoplist__item-btn-xs {
  margin-right: 15px;
  font-size: 16px;
}

a.workshoplist__item:hover > .workshoplist__item-btn {
  background-color: #e1001a;
  background: url('../../img/auswaehlen.svg'), linear-gradient(to right,#a90329 0,#e1001a 8%,#e1001a 17%,#6d0019 100%);
  background-repeat: no-repeat;
  background-position: right 15px center, center;
  background-size: 28px, cover;
}

.isDisabled {
  cursor: not-allowed;
  text-decoration: none;
}

.isDisabled:hover {
  > .workshoplist__item-btn {
    opacity: 0.5;
  }
}

//.workshoplist__item-btnarrow {
//  font-size: 40px;
//  line-height: 60px;
//}

.eventdetail__box {
  border: 1px solid $theme-primary;
  padding: 20px 20px 0 20px;
  margin-bottom: 50px;
}

.eventdetail__descitem,
.eventdetail__boxitem {
  margin-bottom: 30px;
}

.eventdetail__descitem-title,
.eventdetail__boxitem-title {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
}

@media (min-width: 768px) {

  .workshoplist__item-status {
    width: auto;
  }

  .workshoplist__item {
    font-size: 22px;
  }

  .workshoplist__item-btn {
    font-size: 14px;
  }

}


@media (min-width: 992px) {

  .workshoplist__item {
    font-size: 18px;
  }

  .workshoplist__item-btn {
    font-size: 12px;
  }

}


@media (min-width: 1200px) {

  .workshoplist__item {
    font-size: 22px;
  }

  .workshoplist__item-btn {
    font-size: 16px;
  }

}