.textpic {
  padding: rem(30px 0);
}

.textpic__picture-wrapper {
  width: 100%;
  height: rem(255px);
  height: 67.5vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.textpic__text {
  width: 100%;
  padding: rem(20px);
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .flexbox .textpic {
    display: flex;
  }

  .textpic__picture {
    float: left;
    width: 50%;
    padding-right: rem(20px);
  }

  .textpic__picture-wrapper {
    height: 310px;
    height: 37.5vh;
  }

  .flexbox .textpic__picture {
    display: flex;
  }

  .flexbox .textpic__picture-wrapper {
    height: auto;
    //min-height: rem(310px);
  }

  .textpic__text {
    width: 50%;
    float: left;
  }

  .textpic--change-pos .textpic__picture {
    position: relative;
    left: 50%;
    padding: rem(0 0 0 20px);
  }

  .textpic--change-pos .textpic__text {
    position: relative;
    left: -50%;
  }

  .textpic--change-pos .textpic__text-wrapper {
    float: right;
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .textpic {
    padding: rem(50px 0);
  }

  .textpic__text-wrapper {
    max-width: 450px;
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .textpic__text-wrapper {
    max-width: 545px;
  }

  .textpic--slimtext .textpic__text-wrapper {
    padding-right: 20%;
  }

  .textpic--slimtext.textpic--change-pos .textpic__text-wrapper {
    padding-right: 0;
    padding-left: 20%;
  }


}