// General: Reset List Styles
nav ul {
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.fixed .hidden--fixed {
  display: none;
}

// Meta Navigation
.metanav {
  position: relative;
  //background: $metanav-color;
  background: rgba(40, 40, 40, 0.55);
  height: rem(40px);
  z-index: 100;
}
.metanav__container,
.metanav__row,
.metanav__col {
  height: 100%;
}
.metanav__list {
  position: relative;
  float: right;
  top: 50%;
  transform: translateY(-50%);
}
.metanav__listitem {
  float: left;
}
.metanav__listitem-link {
  color: #FFF;
  margin-left: rem(15px);
}
.metanav__listitem-link:hover {
  color: #FFF;
}
.metanav__listitem-link--icon {
  display: block;
  font-size: 0;
  width: rem(20px);
  height: rem(20px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: rem(20px);
  transition: all 0.3s;
}

.metanav__listitem-link--icon:hover {
  transform: scale(1.1);
}

.metanav.metanav--textonly .metanav__listitem-link--icon {
  font-size: rem(16px);
  width: auto;
  height: auto;
  background: none!important;
}

.metanav.metanav--textonly .metanav__listitem-link--icon:hover {
  transform: none;
}

.metanav:not(.metanav--textonly) .underline a:before {
  content: none;
}

.mainnav {
  //position: fixed;
  width: 100%;
  height: rem(60px);
  //background: $theme-primary;
  background: rgba(40, 40, 40, 0.55);
  z-index: 100;
}

.mainnav.mainnav--transparent {
  background: transparentize($theme-primary, 0.2);
}

.logo {
  background: rgba(40, 40, 40, 0.8);
  position: relative;
  z-index: 1000;
}

.mainnav__logo {
  width: rem(220px);
  height: rem(50px);
  float: left;
  //padding: rem(10px 0);
  z-index: 9999;
  margin-top: 10px;
}

.mainnav__logo figure {
  max-width: 100%;
  max-height: 100%
}

.mainnav__logo figure img {
  max-width: 100%;
  max-height: rem(40px);
}

.mainnav__menu-icon {
  float: right;
  top: rem(8px);
  z-index: 9999;
}

.mainnav__list1 {
  display: none;
  background: $theme-primary;
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  z-index: 9998;
  overflow-y: auto;
  border-top: rem(1px) solid rgba(255,255,255,0.3)
}

.mainnav__list2 {
  display: none;
  background: lighten($theme-primary,5%);
}

.mainnav__list3 {
  display: none;
  background: lighten($theme-primary,10%);
}

.mainnav__listitem1 {
  position: relative;
}

.mainnav__listitem1-link {
  display: block;
  position: relative;
  padding: rem(8px 10px);
  border-bottom: rem(1px) solid rgba(255,255,255,0.2);
}

//.mainnav__listitem1-link:before {
//  content: "|";
//  position: absolute;
//  top: 0;
//  left: 0;
//}
//
//.mainnav__listitem1:first-child .mainnav__listitem1-link:before {
//  display: none;
//}

.mainnav__listitem1-link {
  font-size: rem(20px);
  color: $text-color-light;
}

.mainnav__listitem1-link:hover,
.mainnav__listitem1-link:focus,
.mainnav__listitem1-link:active {
  color: #FFF;
  background: rgba(255,255,255,0.1);
}

.icon-unfold {
  position: absolute;
  right: 0;
  top: 0;
  width: rem(64px);
  height: rem(44px);
  z-index: 1000;
  cursor: pointer;
  text-align: center;
  border-left: rem(1px) solid rgba(255,255,255,0.5);
}

.icon-unfold:before {
  display: block;
  font-family: FontAwesome;
  content: "\f107";
  color: #fff;
  font-size: rem(32px);
  transition: transform 0.3s;
}

.icon-unfold.icon-unfold--down:before {
  transform: rotate(180deg);
}

.mainnav__listitem2 {
  position: relative;
}

.mainnav__listitem2-link:hover,
.mainnav__listitem2-link:focus,
.mainnav__listitem2-link:active {
  color: #FFF;
  background: rgba(255,255,255,0.1);
}

.mainnav__listitem2-link {
  display: block;
  padding: rem(8px 10px 8px 20px);
  color: $text-color-light;
  border-bottom: rem(1px) solid rgba(255,255,255,0.5);
}

.mainnav__list2 .icon-unfold {
  height: rem(38px);
}

.mainnav__list2 .icon-unfold:before {
  font-size: rem(26px);
}

.mainnav__listitem3-link {
  display: block;
  padding: rem(8px 10px 8px 30px);
  color: $text-color-light;
  border-bottom: rem(1px) solid rgba(255,255,255,0.5);
}

.mainnav__listitem3-link:hover,
.mainnav__listitem3-link:focus,
.mainnav__listitem3-link:active {
  color: #FFF;
  background: rgba(255,255,255,0.1);
}

header {
  height: 60px;
}

.mainnav__logo--fixed {
  //display: none;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  header {
    height: auto;
  }

  .mainnav {
    position: relative;
    height: rem(100px);
    margin-top: 0;
  }

  #content {
    padding-top: 100px;
  }

  .mainnav > .container {
    position: relative;
    //height: 100%;
  }

  .mainnav__list1 {
    left: 50%;
    //top: rem(100px);
    border: none;
    top: 100px;
  }

  .mainnav__logo {
    height: 100%;
    //padding: rem(20px 0);
    margin-top: 0;
  }

  .fixed .mainnav__logo--fixed {
    display: inline-block;
  }

  .mainnav__logo figure {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .mainnav__logo figure img {
    position: absolute;
    top: 20px;
    left: 0;
    height: auto;
    max-height: rem(60px);
  }

  .mainnav__menu-icon {
    top: rem(28px);
  }

  .logo {
    //background: transparent;
  }

  .claim {
    font-size: rem(40px);
    font-weight: 700;
    color: #fff;
    line-height: rem(100px);
    text-transform: uppercase;
  }

  .fixed .mainnav__listitem1-link:after {
    bottom: -2px;
  }

}



// Large devices (desktops, 992px and up)
@media (min-width: 992px) {



  #content {
    padding-top: 0;
  }

  .mainnav__outer {
    background: rgba(40,40,40,.55);
  }

  header {
    height: rem(196px);
  }

  .container {
    width: 980px;
  }

  .mainnav {
    height: rem(100px);
  }

  .logo {
    height: rem(100px);
    //background: rgba(0, 40, 81, 0.8);
  }

  .mainnav__logo {
    width: rem(200px);
    //padding: rem(35px 0);
    position: absolute;
  }

  .mainnav__logo figure img {
    max-height: rem(160px);
    max-width: rem(180px);
  }

  .mainnav__list1 {
    display: block!important;
    position: relative;
    //top: rem(88px);
    left: 0;
    float: left;
    //width: rem(740px);
    width: 100%;
    background: none;
    border: none;
    overflow: visible;
    top: 0;
  }

  .mainnav__listitem1 {
    float: left;
    width: auto;
    margin-right: 15px;
  }

  .flexbox .mainnav__list1 {
    display: flex!important;
    justify-content: space-between;
  }

  .flexbox .mainnav__listitem1 {
    margin: 0;
    float: none;
  }

  .mainnav__divider {
    padding: rem(15px 0 17px 0);
    color: #fff;
  }

  .fixed .mainnav__divider {
    padding: rem(4px 0 17px 0);
  }

  .mainnav__listitem1-link {
    border: none;
    font-size: rem(15px);
    padding: rem(17px 0 18px 0);
  }

  .mainnav__listitem1-link:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    left: 50%;
    height: rem(6px);
    bottom: 0;
    background: $theme-secondary;
    transition: all 0.3s;
  }

  .fixed .mainnav__listitem1-link:after {
    bottom: -2px;
  }

  .mainnav__listitem1-link:hover {
    background: none;
  }

  .mainnav__listitem1:hover .mainnav__listitem1-link:after {
    width: 100%;
    left: 0;
  }

  .mainnav__listitem1-link.active:after {
    width: 100%;
    left: 0;
  }

  .mainnav__listitem1:hover .mainnav__list2 {
    display: block;
    position: absolute;
    top: rem(63px);
    left: 0;
    background: $theme-primary;
    width: rem(210px);
    padding: rem(20px 0 10px 0);
  }

  .mainnav__listitem2-link {
    border: none;
  }

  .fixed .mainnav {
    position: fixed;
    height: rem(75px);
    top: rem(-75px);
    margin-top: rem(75px);
    z-index: 1000;
    background: $theme-primary;
  }

  .fixed .mainnav.mainnav--transparent {
    background: $theme-primary;
  }

  .fixed .mainnav__list1 {
    top: rem(22px);
    width: rem(780px);
  }

  .fixed .mainnav__listitem1-link {
    padding: rem(5px 0 25px 0);
  }

  .fixed .mainnav__listitem1:hover .mainnav__list2 {
    top: rem(58px);
  }

  .fixed .mainnav__logo {
    padding-bottom: rem(10px);
    width: rem(150px);
  }

  .fixed .mainnav__logo figure img {
    max-height: rem(45px);
    top: 15px;
  }

  .claim {
    //text-align: center;
    font-size: rem(50px);
    position: absolute;
    z-index: 2;
  }

  .fixed header {
    height: rem(75px);
  }

  .fixed .logo {
    height: rem(75px);
  }

  .fixed .claim {
    display: none;
  }

  .fixed .search {
    display: none;
  }

  header .search {
    //padding: 32px 20px 0 0;
    text-align: right;
    border-top: 33px rgba(0,40,81,.8) solid;
    border-bottom: 35px rgba(0,40,81,.8) solid;
    //background: rgba(0,40,81,.55);
    width: 200px;
    float: right;
  }

  header .search input {
    border: 1px solid #dadada;
    height: rem(44px);
    width: 100%;
    margin-bottom: rem(15px);
    padding: rem(0 15px);
    border-radius: 0!important;
    box-shadow: none!important;
    -webkit-appearance: none;
  }

  header .search input {
    width: 170px;
    background-color: transparent;
    padding: 5px 10px;
    height: 32px;
    vertical-align: middle;
    color: #fff;
    border: 0;
    margin-bottom: 0;
    background: rgba(40,40,40,.55);
  }

  header .search input::placeholder {
    color: #fff;
  }

  header .search input:focus {
    outline: 0;
  }

  header .search input[type=submit] {
    background-image: url(../img/Icon_Suche.svg);
    background-repeat: no-repeat;
    width: 30px;
    background-position: 0 4px;
  }

  .search:before {
    position: absolute;
    width: 5000px;
    height: 10rem;
    top: 0;
    left: -4975px;
    content: "";
    background: rgba(40,40,40,.8);
    z-index: 1;
  }

  .search:after {
    position: absolute;
    width: 5000px;
    height: 10rem;
    top: 0;
    right: -4980px;
    content: "";
    background: rgba(40,40,40,.8);
    z-index: 1;
  }

  .fixed .mainnav__inner {

  }

  .fixed .mainnav__innerinner {
    position: absolute;
    top: -75px;
    right: 20px;
  }

  .fixed .mainnav__outer {
    background-color: transparent;
  }

  .fixed .mainnav__outer .container {
    position: relative;
  }

  .fixed .flexbox .mainnav__list1 {
    display: block !important;
  }

  .mainnav__listitem1-link:hover,
  .mainnav__listitem1-link:focus,
  .mainnav__listitem1-link:active {
    color: #FFF;
    background: rgba(255,255,255,0.0);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  header {
    height: rem(200px);
  }

  .mainnav__logo {
    width: rem(250px);
    //padding: rem(0);
  }

  .mainnav__logo figure img {
    max-width: rem(180px);
  }

  .fixed .mainnav__list1 {
    width: rem(980px);
  }

  .mainnav__list1 {
    //width: rem(850px);
    //margin-left: rem(30px);
    //top: rem(137px);
  }

  .mainnav__listitem1-link {
    font-size: rem(17px);
  }

  .fixed .mainnav__divider {
    padding: rem(7px 0 17px 0);
  }

  header .search input {
    width: 190px;
  }

  header .search {
    width: auto;
  }

  header .search:before {
    left: auto;
    right: 240px;
  }

  .fixed .mainnav__listitem1-link:after {
    bottom: 1px;
  }

}