.webinarlist {
  margin-bottom: 50px;
}

.webinarlist__item {
  padding: 20px;
  border-top: 2px solid $theme-primary;
  position: relative;
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    background-image: url("../../../img/auswaehlen_blau.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    right: 15px;
    bottom: 15px;
    width: 28px;
    height: 28px;
  }
  img {
    border-top-left-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
  }
}

.webinarlist__item:hover {
  background-color: $theme-primary;
  color: #fff!important;
  h4, p {
    color: #fff!important;
  }
  &:after {
    background-image: url("../../../img/auswaehlen.svg");
  }
}

.webinarlist__item-desc {
  margin-right: 40px;
}

@media (max-width: 767px) {

  .webinarlist__item-title {
    margin-top: 15px;
  }

}