.form-booking {
  margin-bottom: 50px;
}

.form-booking .calendarlist__item:not(.calendarlist__item--group) {
  margin-bottom: rem(15px);
  border-bottom: 1px solid $theme-primary;
}

.calendarlist__item--margin {
  margin-bottom: 38px!important;
}

.calendarlist__item--group {
  background: none;
}

.calendarlist__item--group:last-of-type {
  border-bottom: none;
}

.policy-paragraph--first {
  margin-bottom: 20px!important;
}

.policy-paragraph {
  border-bottom: 1px solid $theme-primary;
  padding-left: 34px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  display: block;
}

.form-submit-button {
  border: none;
  background: linear-gradient(to right,#a90329 0,#e1001a 8%,#e1001a 17%,#6d0019 100%);
  border-bottom: 1px solid #002851;
  color: #fff;
  width: 100%;
  line-height: 40px;
  border-radius: .5rem;
  font-weight: bold;
  font-size: 14px;
}

.form-booking__skillitem,
.form-booking__additem {
  width: 100%;
  display: block;
  border: 1px solid #dadada;
  line-height: rem(44px);
  padding: rem(0 15px);
  margin-bottom: rem(15px);
}

.form-booking__additem {
  background-color: #dadada;
  color: #333;
  margin-bottom: 38px!important;
  text-align: left;
  background-image: url("../../../img/plus.svg");
  background-position: right 15px center;
  background-repeat: no-repeat;
}

.form-booking__skillitem-title {
  display: block;
}

.form-booking__skillitem-options > label {
  font-weight: normal;
}

.form-booking__skillitem-options > label:not(:last-of-type) {
  margin-right: 10px;
}


.divider-blue {
  border-color: #002851;
}

.participants__form-item-title {
  display: inline-block;
  margin-top: 20px;
  margin-left: 15px;
}

.remove {
  float: right;
  margin-top: 20px;
  margin-right: 15px;
}


@media (max-width: 767px) {

  .form-booking {
    font-size: 12px!important;
  }

  .form-booking__additem {
    background-size: 18px;
  }

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .form-booking {
    font-size: 14px!important;
  }

  .form-booking__skillitem {
    height: rem(44px);
  }

  .form-booking__skillitem-title {
    display: inline-block;
    //width: 52%;
  }

  .form-booking__skillitem-options {
    float: right;
  }

  .policy-paragraph {
    margin-bottom: 60px;
  }

  .form-submit-button {
    line-height: 60px;
    font-size: 18px;
  }

  .calendarlist__item--margin {
    margin-bottom: 58px !important;
  }


}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .form-booking {
    font-size: 16px!important;
  }

  .form-booking .calendarlist__item:not(.calendarlist__item--group) {
    margin-bottom: rem(20px);
  }

  .form-booking__skillitem-options > label:not(:last-of-type) {
    margin-right: 20px;
  }

  .form-booking__skillitem,
  .form-booking__additem {
    height: rem(60px);
    line-height: rem(60px);
    margin-bottom: rem(20px);
  }

  .form-booking__additem {
    margin-bottom: 58px!important;
  }

}