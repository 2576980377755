.mainpic {
  position: relative;
  width: 100%;
  height: rem(500px);
  height: 100vh;
  overflow: hidden;
}

.mainpic--slider .slick-list, .mainpic--slider .slick-track {
  height: 100%;
}

.mainpic__container {
  position: relative;
  height: 100%;
}

.mainpic__item {
  position: relative;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
}

.mainpic__itemoverlay {
  position: absolute;
  bottom: rem(70px);
  background-color: $theme-secondary;
  width: rem(300px);
  padding: rem(30px 10px 20px 10px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.mainpic--transparent-overlay .mainpic__itemoverlay {
  background-color: rgba(0,0,0,0.2);
}

.mainpic__itemoverlay h1 {
  color: #fff;
}

.mainpic__itemoverlay p.large {
  color: #fff;
}

.mainpic--full-overlay .mainpic__container {
  position: static;
}

.mainpic--full-overlay .mainpic__itemoverlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transform: none;
}

header + .mainpic.mainpic--full-overlay .mainpic__itemoverlay {
  top: rem(60px);
}

.mainpic--full-overlay .mainpic__itemoverlay-content {
  position: absolute;
  bottom: rem(100px);
  width: rem(280px);
  left: 50%;
  margin-left: rem(-140px);
}

.mainpic__itemoverlay-content .btn {
  margin: rem(20px auto 0 auto);
}

.mainpic__item:not(:first-of-type) {
  display: none;
}

.mainpic__item.mainpic--slider:not(:first-of-type) {
  display: block;
}

.mainpic--piconly-small .mainpic__itemoverlay,
.mainpic--piconly-mid .mainpic__itemoverlay,
.mainpic--piconly-700 .mainpic__itemoverlay,
.mainpic--piconly-large .mainpic__itemoverlay {
  display: none!important;
}

.mainpic.mainpic--piconly-small {
  height: rem(60px);
}

.mainpic.mainpic--piconly-small .slick-dots {
  display: none!important;
}

.mainpic.mainpic--piconly-mid  {
  height: rem(200px);
}

.mainpic.mainpic--piconly-700  {
  height: rem(350px);
}

.mainpic.mainpic--piconly-mid .slick-dots {
  display: none!important;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  header + #content .mainpic {
    top: rem(-180px);
    margin-bottom: rem(-180px);
  }

  .mainpic__itemoverlay {
    width: rem(480px);
    padding: rem(60px 45px 30px 45px);
  }

  header + #content .mainpic.mainpic--full-overlay .mainpic__itemoverlay {
    top: rem(180px);
  }

  .mainpic--full-overlay .mainpic__itemoverlay-content {
    bottom: rem(150px);
    width: rem(420px);
    margin-left: rem(-210px);
  }

  .mainpic--left-overlay .mainpic__itemoverlay {
    left: 10px;
    transform: none;
    text-align: left;
  }

  .mainpic--left-overlay .mainpic__itemoverlay .btn {
    margin: rem(20px 0 0 0);
  }

  .mainpic--right-overlay .mainpic__itemoverlay {
    left: auto;
    right: 10px;
    transform: none;
    text-align: left;
  }

  .mainpic--right-overlay .mainpic__itemoverlay .btn {
    margin: rem(20px 0 0 0);
  }

  .itemoverlay__container {
    position: relative;
    height: 100%;
    max-width: 100%;
    z-index: 50;
  }

  .mainpic--full-overlay.mainpic--right-overlay .mainpic__itemoverlay {
    right: 0;
  }

  .mainpic--full-overlay.mainpic--right-overlay .mainpic__itemoverlay-content {
    left: auto;
    right: 0;
  }

  .mainpic--full-overlay.mainpic--left-overlay .mainpic__itemoverlay {
    left: 0;
  }

  .mainpic--full-overlay.mainpic--left-overlay .mainpic__itemoverlay-content {
    left: 0;
    right: auto;
    margin: 0;
  }

  .mainpic.mainpic--piconly-small {
    height: rem(150px);
  }

  .mainpic.mainpic--piconly-mid  {
    height: rem(350px);
  }

  .mainpic.mainpic--piconly-700  {
    height: rem(500px);
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  header + #content .mainpic {
    top: rem(-220px);
    margin-bottom: rem(-220px);
  }

  .mainpic__itemoverlay {
    width: rem(640px);
    padding: rem(60px 45px 30px 45px);
  }

  header + #content .mainpic.mainpic--full-overlay .mainpic__itemoverlay {
    top: rem(206px);
  }

  .mainpic--full-overlay .mainpic__itemoverlay-content {
    bottom: rem(100px);
    width: rem(560px);
    margin-left: rem(-280px);
  }

  .mainpic--full-overlay.mainpic--right-overlay .mainpic__itemoverlay-content {
    width: rem(560px);
  }

  .mainpic.mainpic--piconly-small {
    height: rem(200px);
  }

  .mainpic.mainpic--piconly-mid  {
    height: rem(500px);
  }

  .mainpic.mainpic--piconly-700  {
    height: rem(600px);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  header + #content .mainpic {
    top: rem(-250px);
    margin-bottom: rem(-250px);
  }

  .mainpic--full-overlay .mainpic__itemoverlay-content {
    bottom: rem(150px);
    width: rem(650px);
    margin-left: rem(-325px);
  }

  .mainpic--full-overlay.mainpic--right-overlay .mainpic__itemoverlay-content {
    width: rem(650px);
  }

  header + #content .mainpic.mainpic--full-overlay .mainpic__itemoverlay {
    top: rem(191px);
  }

  .mainpic.mainpic--piconly-small {
    height: rem(250px);
  }

  .mainpic.mainpic--piconly-700  {
    height: rem(700px);
  }

}