.hovergallery__item {
  display: block;
  position: relative;
  width: 100%;
  height: rem(195px);
  height: 60vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.hovergallery__item-text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: rem(0 20px);
  background: rgba(0,0,0,0.5);
  text-align: center;
  transform: scale(0);
  transition: all 0.3s;
  cursor: default;
}

.hovergallery__item-text * {
  color: #FFF;
}

.hovergallery__item-textwrapper {
  position: absolute;
  top: 50%;
  left: rem(10px);
  right: rem(10px);
  transform: translateY(-50%);
}

.hovergallery__item:hover .hovergallery__item-text,
.hovergallery__item:active .hovergallery__item-text,
.hovergallery__item:focus .hovergallery__item-text {
  transform: scale(1);
}


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .hovergallery__item {
    width: 50%;
    height: rem(230px);
    height: 30vw;
    float: left;
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {



  .hovergallery__item-text {
    top: rem(10px);
    right: rem(10px);
    bottom: rem(10px);
    left: rem(10px);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .hovergallery__item-text {
    top: rem(35px);
    right: rem(40px);
    bottom: rem(35px);
    left: rem(40px);
  }

  .hovergallery__item-textwrapper {
    padding: rem(0 10px);
  }

  .hovergallery__item {
    width: 33.3333%;
    height: rem(210px);
    height: 20vw;
    float: left;
  }

}

@media (min-width: 1400px) {

  .hovergallery__item-textwrapper {
    padding: rem(0 50px);
  }

}