.hoverbox {
  //padding: rem(50px 0);
}

.hoverbox__item {
  position: relative;
  width: 100%;
  height: rem(200px);
  height: 72.5vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: rem(1px);
  padding: rem(0 10px);
}

.hoverbox__item-link {
  position: absolute;
  bottom: 0;
  height: rem(200px);
  height: 72.5vw;
  left: 0;
  right: 0;
  display: block;
  color: #FFF;
  text-align: center;
  background: transparentize($theme-primary, 0.55);
  transition: 0.2s;
}

.hoverbox__item-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: rem(280px);
}

.hoverbox__item-btn:hover {
  background: none!important;
}

.hoverbox__item *:hover {
  color: #FFF!important;
}

/*.hoverbox__item-link:after {
  content: "";
  width: 100%;
  height: 10px;
  position: absolute;
  left: 0;
  bottom: rem(-10px);
  background: transparentize($theme-primary, 0.2);
}*/

.hoverbox__item-text {
  position: absolute;
  display: block;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  font-size: rem(15px);
  //font-weight: bold;
  transition: 0.4s;
}

.hoverbox__item-headline {
  font-size: rem(20px);
  display: block;
  font-weight: 700;
  margin-bottom: rem(15px);
}

.hoverbox__item-desc {
  display: block;
}


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .hoverbox__item {
    width: 33.3333%;
    height: rem(225px);
    height: 30vw;
    float: left;
    margin-bottom: rem(0);
  }

  .hoverbox__item-link {
    height: rem(225px);
    height: 30vw;
    //left: rem(20px);
    //right: rem(20px);
  }

  .hoverbox__item-btn {
    max-width: 90%!important;
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .hoverbox__item-link {
    //left: rem(50px);
    //right: rem(50px);
  }


}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .hoverbox__item {
    height: rem(585px);
  }

  .hoverbox__item-link {
    //left: 15%;
    //right: 15%;
    height: rem(80px);
  }

  .hoverbox__item:hover .hoverbox__item-link {
    height: rem(585px);
  }

  .hoverbox__item-btn.btn-white {
    opacity: 0;
    transition: all 0.3s!important;
  }

  .hoverbox__item:hover .hoverbox__item-btn.btn-white {
    opacity: 1;
  }

  .hoverbox__item-text {
    bottom: rem(15px);
    font-size: rem(20px);
    top: inherit;
    transform: inherit;
  }

  .hoverbox__item-headline {
    font-size: rem(30px);
    line-height: rem(45px);
    margin-bottom: rem(0);
  }

  .hoverbox__item-desc {
    display: none;
    line-height: rem(30px);
  }

  .hoverbox__item *:hover .hoverbox__item-desc {
    display: block;
    padding: rem(0 100px);
  }

  .hoverbox__item *:hover .hoverbox__item-text {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }

  .hoverbox__item *:hover .hoverbox__item-headline {
    margin-bottom: rem(15px);
  }

}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1500px) {

  .hoverbox__item-headline {
    font-size: rem(35px);
    line-height: rem(50px);
  }

}