.linkbox {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: rem(30px 0 30px 0);
}

.linkbox__content {
  position: relative;
  background: rgba($theme-primary,0.55);
  padding: rem(100px 10px 40px 10px);
  text-align: center;
  max-width: rem(480px);
  margin: 0 auto;
}

.linkbox__content-text {
  margin-bottom: rem(50px);
}

.linkbox__content-text h2 {
  font-size: rem(50px);
}

.linkbox__btn {
  margin: 0 auto;
  border-top-left-radius: 10px!important;
  border-bottom-right-radius: 10px!important;
}

.linkbox__content * {
  color: #FFF;
  font-size: rem(18px);
}

.linkbox__content-iconwrapper {
  position: absolute;
  width: rem(100px);
  height: rem(100px);
  top: rem(-30px);
  left: 50%;
  margin-left: rem(-50px);
  background-color: $theme-primary;
  padding: rem(25px);
}

.linkbox__content-icon {
  position: relative;
  width: rem(50px);
  height: rem(50px);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.linkbox.linkbox--bgcolor {
  background-image: none!important;
  background-color: $theme-primary;
}

.linkbox.linkbox--bgcolor .linkbox__content {
  background: none;
  max-width: rem(560px);
}

.linkbox.linkbox--bgcolor .linkbox__content-iconwrapper {
  width: rem(130px);
  height: rem(115px);
  margin-left: rem(-65px);
  padding: rem(40px 40px 25px 40px);
  background-color: transparent;
  border-bottom: rem(2px) solid #FFF;
}

.linkbox.linkbox--mini .linkbox__content-iconwrapper {
  display: none;
}

.linkbox.linkbox--mini .linkbox__content {
  padding: rem(30px 10px);
  max-width: none;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .linkbox__content {
    padding: rem(140px 40px 40px 40px);
    max-width: rem(640px);
  }

  .linkbox__content-text {
    margin-bottom: rem(100px);
  }

  .linkbox.linkbox--left .linkbox__content {
    width: 50%;
    margin: 0;
    text-align: left;
  }

  .linkbox.linkbox--left .linkbox__btn {
    margin: 0;
  }

  .linkbox.linkbox--left .linkbox__content-iconwrapper {
    left: auto;
    margin-left: 0;
  }

  .linkbox.linkbox--right .linkbox__content {
    width: 50%;
    left: 50%;
    margin: 0;
    text-align: left;
  }

  .linkbox.linkbox--right .linkbox__btn {
    margin: 0;
  }

  .linkbox.linkbox--right .linkbox__content-iconwrapper {
    left: auto;
    margin-left: 0;
  }

  .linkbox.linkbox--right .linkbox__content-text {
    margin-bottom: rem(75px);
  }

  .linkbox.linkbox--mini .linkbox__content {
    @include clearfix();
    text-align: left;
    display: flex;
    padding: rem(10px 0 0 0);
  }

  .linkbox.linkbox--mini .linkbox__content-text {
    width: 62%;
    float: left;
    margin-right: 5%;
    margin-bottom: 0;
  }

  .linkbox.linkbox--mini .linkbox__content-link {
    position: relative;
    width: 33%;
    float: left;
  }

  .linkbox.linkbox--mini .linkbox__btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 100%;
    max-width: rem(300px);
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .linkbox.linkbox--mini .linkbox__btn {
    float: right;
    width: rem(300px);
  }

}