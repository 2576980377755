.imagegallery {
  padding: rem(50px 0);
}

.imagegallery__linkitem {
  position: relative;
  display: block;
  margin-bottom: rem(20px);
  height: rem(195px);
  height: 60vw;
  overflow: hidden;
}

.imagegallery__img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 1.5s;
  text-align: center;
}

.imagegallery__img:after {
  content: "\f002";
  font-family: FontAwesome;
  font-size: rem(0px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.0);
  transition: all 0.3s;
  color: #FFF;
  line-height: rem(195px);
  line-height: 60vw;
  transition: all 0.2s;
}

.touch .imagegallery__img:after {
  font-size: rem(48px);
  opacity: 0.5;
}

.imagegallery__linkitem:hover .imagegallery__img:after {
  font-size: rem(48px);
  background-color: rgba(0,0,0,0.5);
}


.imagegallery__linkitem:hover .imagegallery__img {
  transform: scale(1.1);
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .imagegallery__linkitem {
    height: rem(160px);
    margin-bottom: rem(40px);
  }

  .imagegallery__img:after {
    line-height: rem(160px);
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .imagegallery__linkitem {
    height: rem(200px);
  }

  .imagegallery__img:after {
    line-height: rem(200px);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .imagegallery__linkitem {
    height: rem(230px);
  }

  .imagegallery__img:after {
    line-height: rem(230px);
  }

}