.refslider {
  padding: rem(50px 0);
}

.refslider__item {
  text-align: center;
}

.refslider__itemlist {
  position: relative;
}

.refslider__item-img {
  position: relative;
  height: rem(190px);
  height: 56vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: rem(0 0 30px 0);
}

.refslider .slick-arrow {
  top: 0;
  background-color: $theme-primary;
  transform: none;
  height: rem(190px);
  height: 56vw;
  width: rem(20px);
  background-image: none;
  opacity: 1;
}

.refslider .slick-prev {
  left: 0;
}

.refslider .slick-prev:after {
  content:"\f104";
  font-family: FontAwesome;
  font-size: rem(24px);
  color: #FFF;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: rem(20px);
  height: rem(20px);
  line-height: rem(20px);
}

.refslider .slick-next {
  right: 0;
}

.refslider .slick-next:after {
  content:"\f105";
  font-family: FontAwesome;
  font-size: rem(24px);
  color: #FFF;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: rem(20px);
  height: rem(20px);
  line-height: rem(20px);
}


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .refslider__item-text {
    padding: rem(0 50px);
  }

  .refslider__item-img {
    height: rem(400px);
  }

  .refslider .slick-prev {
    left: rem(20px);
  }

  .refslider .slick-next {
    right: rem(20px);
  }

  .refslider .slick-arrow {
    background: rgba(0,0,0,0.5);
    width: rem(44px);
    height: rem(400px);
  }

  .refslider .slick-prev:after {
    font-size: rem(44px);
    width: rem(44px);
    height: rem(44px);
    line-height: rem(44px);
  }

  .refslider .slick-next:after {
    font-size: rem(44px);
    width: rem(44px);
    height: rem(44px);
    line-height: rem(44px);
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .refslider__item-img {
    height: rem(500px);
  }

  .refslider .slick-arrow {
    height: rem(500px);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .refslider__item-text {
    padding: rem(0 90px);
  }

  .refslider__item-img {
    height: rem(550px);
  }

  .refslider .slick-arrow {
    height: rem(550px);
  }


}