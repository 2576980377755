.articles {
  padding: rem(50px 0);
}

.articles__row {
  display: flex;
}

.articles__item {
  width: 100%;
  position: relative;
  padding-bottom: rem(30px);
  margin-bottom: rem(35px);
}

.articles__item:after {
  content: "";
  display: block;
  height: rem(2px);
  position: absolute;
  bottom: 0;
  left: rem(20px);
  right: rem(20px);
  background-color: $theme-secondary;
}

.articles__row:last-of-type .articles__item {
  margin-bottom: 0;
  padding-bottom: 0;
}

.articles__row:last-of-type .articles__item:after {
  content: none;
}

.articles__item-img {
  width: 100%;
  height: rem(200px);
  height: 65vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: rem(20px);
}


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .articles__item {
    display: flex;
  }

  .articles__item-img {
    height: rem(125px);
    margin-bottom: 0;
  }

  .articles__item-btn {
    position: absolute;
    bottom: rem(10px);
    left: rem(20px);
    right: rem(20px);
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .articles__item {
    padding-bottom: rem(30px);
    margin-bottom: rem(55px);
  }

  .articles {
    padding: rem(75px 0);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .articles__item-btn {
    left: auto;
    width: rem(300px);
  }

}