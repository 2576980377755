// Color Configuration
$theme-primary: #282828;
$theme-secondary: #e1001a;
$text-color-light: #fff;
$text-color-dark: #3e3d40;
$metanav-color: #282828;
$footer-color: #282828;
$footer-metanav-color: #c7c8ca;

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('../fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

// Font Configuration
$font-family-base:      'Open Sans', Arial, Verdana, sans-serif;
$font-size-h1:          50px;
$font-size-h2:          40px;
$font-size-h3:          30px;
$font-size-h4:          20px;
$font-size-h5:          16px;
$font-size-base:        16px;
$font-size-base-large:  20px;


// Import Components
@import "_header";
@import "_mainpicture";
@import "_textpic";
@import "_cards";
@import "_hover-infobox";
@import "_linkbox";
@import "_article-list";
@import "_imagegallery";
@import "_hovergallery";
@import "_reference-slider";
@import "_contact";
@import "_footer";
@import "_event-detail";
@import "_event-list";
@import "_event-booking";
@import "_webinar-list";
