.icon-heading:not(.icon-heading--detail,.icon-heading--form) {
  margin-bottom: 20px;
}

.icon-heading:before {
  display: inline-block;
  width: 30px;
  height: 30px;
  content: '';
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  //margin-bottom: 15px;
  margin-right: 10px;
  float: left;
}

.icon-heading--searched:before {
  background-image: url("../../../img/Icon_Gesuchte-Seminare.svg");
}

.icon-heading--calendar:before {
  background-image: url("../../../img/Icon_Kalender.svg");
}

.icon-heading--knowledge:before {
  background-image: url("../../../img/Icon_Alternative-Seminare.svg");
}

.icon-heading--form:before {
  background-image: url("../../../img/Icon_Anmeldeformular.svg");
}

.icon-heading--filter:before {
  background-image: url("../../../img/Icon_Filter.svg");
}

.icon-heading--video:before {
  background-image: url("../../../img/Icon_Webinare.svg");
}

.filter-left {
  color: $theme-primary;
  margin-bottom: 40px;
}

.filter-paused .icon-heading--filter:before {
  opacity: .2;
}

.filter-paused .filter-left__section-options input[type='checkbox']:before {
  opacity: .2
}

.eventlist,
.calendarlist {
  margin-bottom: 80px;
}

.eventlist__item {
  text-align: left;
  font-weight: bold;
  background-color: $theme-primary;
  color: #fff;
  display: block;
  width: 100%;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 50px 0 15px;
  margin-bottom: 15px;
  font-size: 14px;
  height: 60px;
  //line-height: 60px;
  background-image: url("../../../img/auswaehlen.svg");
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 18px;
  position: relative;

  &:hover, &:focus, &:active {
    color: #fff;
    background-image: url("../../../img/ausgewaehlt.svg");
  }
}

.eventlist__item span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.calendarlist__item {
  text-align: left;
  font-weight: 700;
  color: $theme-primary;
  display: block;
  width: 100%;
  border-top: 1px solid $theme-primary;
  font-size: 14px;
  background-image: url(../../../img/auswaehlen_blau.svg);
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 18px;
  padding: 10px 0px;
}

.calendarlist__item--open {
  background-image: url(../../../img/ausgewaehlt_blau.svg);
}

.calendarlist__item--inquire,
.calendarlist__item--billingaddress,
.calendarlist__item--city {
  &:hover {
    color: #fff;
    background-color: $theme-primary;
    background-image: url(../../../img/auswaehlen.svg);
  }
}

.calendarlist__item--inverted {
  color: #fff;
  background-color: $theme-primary;
  background-image: url(../../../img/ausgewaehlt.svg);
  &:hover {
    color: #fff;
  }
}

.calendarlist__item:last-of-type {
  border-bottom: 1px solid $theme-primary;
}

.mobile-filter {
  width: 100%;
  margin-bottom: 20px;
}

.filter-left__section {
  margin-bottom: 20px;
}

.filter-left__section-head {
  display: block;
  margin-bottom: 10px;
}

.filter-left__section-title {
  font-weight: 700;
  display: block;
  margin-bottom: 3px;
  font-size: 16px;
  /*float: left;*/
  margin-right: 10px;
}

.filter-left__section-options {
  margin-left: 15px;
}

.filter-left__section-options > label {
  display: block;
  font-weight: normal;
  position: relative;
}

.filter-left__section-options input[type='checkbox'] {
  margin-right: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border:none;
}

.filter-left__section-options input[type='checkbox']:checked {
  &:before {
    content:' ';
    display:inline-block;
    background-image: url("../../../img/haken.svg");
    height: 14px;
    width: 18px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 3px;
    left: -14px;
  }
}

.filter-left__reset,
.filter-left__section-reset {
  font-size: 14px;
  border: none;
}

.filter-left {
  display: none;
}

@media (min-width: 768px) {

  .icon-heading:not(.icon-heading--detail,.icon-heading--form) {
    margin-bottom: 50px;
  }

  .filter-left {
    display: block;
  }

  .calendarlist__item {
    //height: 60px;
    //line-height: 60px;
    font-size: 22px;
    padding: 15px 15px;
    background-size: 28px;
  }

  .eventlist__item {
    font-size: 22px;
    background-size: 28px;
  }

  .icon-heading:before {
    width: 40px;
    height: 40px;
    margin-bottom: -10px;
    float: none;
  }

}

@media (min-width: 992px) {

  .icon-heading:before {
    width: 50px;
    height: 50px;
  }

}